import React from "react";

const PrivacyPolicyPage = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p>
        <strong>Effective Date:</strong> 06/01/2023
      </p>

      <h2>1. Introduction</h2>
      <p>
        Welcome to the Brain Stroke Predictor App (the "App"). This Privacy
        Policy outlines how we handle your data when you use our App. We are
        committed to protecting your privacy and ensuring that your personal
        information is handled in a safe and responsible manner.
      </p>

      <h2>2. Data Collection</h2>
      <ul>
        <li>
          <strong>No PII Collection:</strong> We do not collect any Personally
          Identifiable Information (PII) such as names, addresses, or contact
          details.
        </li>

        <li>
          <strong>No Personal Data Storage:</strong> We do not collect, store,
          or retain any personal data from users of the App.
        </li>
        <li>
          <strong>No Usage Data:</strong> We do not track, log, or store any
          usage data or activity related to your use of the App.
        </li>
      </ul>

      <h2>3. Data Sharing</h2>
      <ul>
        <li>
          <strong>No Data Sharing:</strong> Since we do not collect or store any
          personal data, we do not share any information with third parties such
          as advertisers and marketers.
        </li>
      </ul>

      <h2>4. Third-Party Services</h2>
      <ul>
        <li>
          <strong>No Third-Party Analytics:</strong> We do not integrate any
          third-party analytics services that could collect data about your use
          of the App.
        </li>
        <li>
          <strong>No External Links:</strong> The App does not contain links to
          third-party websites or services.
        </li>
      </ul>

      <h2>5. Security</h2>
      <ul>
        <li>
          <strong>Data Transmission:</strong> Data you input into the App is
          transmitted securely to our stateless remote services using
          industry-standard encryption protocols.
        </li>
        <li>
          <strong>No Data Storage:</strong> Our remote services process the data
          statelessly and do not store any personal data.
        </li>
      </ul>

      <h2>6. Changes to This Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page. You are
        advised to review this Privacy Policy periodically for any changes.
        Changes to this Privacy Policy are effective when they are posted on
        this page.
      </p>

      <h2>7. Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please{" "}
        <a
          href="mailto:siyonaraskar@gmail.com?subject=Regarding Brain Stroke Predictor App&body=Please send your question regarding the Brain Stroke Predictor App and we will get back to you as soon as possible : "
          target="_blank"
          rel="noopener noreferrer"
        >
          contact us
        </a>
        .
      </p>

      <h2>8. Consent</h2>
      <p>By using our App, you consent to our Privacy Policy.</p>

      <h2>9. Governing Law</h2>
      <p>
        This Privacy Policy is governed by the laws of Texas in USA, without
        regard to its conflict of law principles.
      </p>
    </div>
  );
};

export default PrivacyPolicyPage;
