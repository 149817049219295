import React from "react";

const EULA = () => {
  return (
    // <div className="page-container">
    <div className="eula-container">
      <h1>End-User License Agreement (EULA) and Disclaimer</h1>
      <p>
        This End-User License Agreement (the "Agreement") is a legal agreement
        between you ("User" or "you") and Siyona ("we," "us," or "our")
        governing your use of the Brain Stroke Predictor App (the "App").
      </p>
      <p>
        By downloading, installing, or using the App, you agree to be bound by
        the terms of this Agreement. If you do not agree to these terms, do not
        download, install, or use the App.
      </p>

      <h2>1. License Grant:</h2>
      <p>
        We grant you a non-exclusive, non-transferable, revocable license to use
        the App for personal, non-commercial purposes, subject to the terms of
        this Agreement.
      </p>

      <h2>2. Restrictions:</h2>
      <p>You agree not to:</p>
      <ul>
        <li>Modify, reverse engineer, decompile, or disassemble the App.</li>
        <li>Use the App for any unlawful purpose.</li>
        <li>Distribute, sublicense, or transfer the App to any third party.</li>
      </ul>

      <h2>3. Intellectual Property:</h2>
      <p>
        All intellectual property rights in and to the App are owned by us or
        our licensors. This Agreement does not transfer any ownership rights.
      </p>

      <h2>4. Termination:</h2>
      <p>
        We may terminate this Agreement and your access to the App at any time,
        with or without cause. Upon termination, you must cease all use of the
        App and destroy any copies in your possession.
      </p>

      <h2>5. Disclaimer of Warranties:</h2>
      <p>
        The App is provided "as-is" without any warranties, express or implied,
        including but not limited to the implied warranties of merchantability,
        fitness for a particular purpose, and non-infringement. We do not
        warrant that the App will be error-free or that it will meet your
        requirements.
      </p>

      <h2>6. Limitation of Liability:</h2>
      <p>
        In no event shall we be liable for any damages (including, without
        limitation, incidental and consequential damages, personal
        injury/wrongful death, lost profits, or damages resulting from lost data
        or business interruption) resulting from the use of or inability to use
        the App, whether based on warranty, contract, tort, or any other legal
        theory, and whether or not we were advised of the possibility of such
        damages.
      </p>

      <h2>7. Medical Disclaimer:</h2>
      <p>
        The App's predictions are for informational purposes only and do not
        constitute medical advice, diagnosis, or treatment. Always seek the
        advice of a qualified healthcare provider with any questions you may
        have regarding a medical condition or symptoms. Do not disregard
        professional medical advice or delay in seeking it because of
        information provided by the App.
      </p>

      <h2>8. Governing Law:</h2>
      <p>
        This Agreement shall be governed by and construed in accordance with the
        laws of Texas in USA, without regard to its conflict of law principles.
      </p>

      <h2>9. Contact Information:</h2>
      <p>
        If you have any questions about this Agreement, please{" "}
        <a
          href="mailto:siyonaraskar@gmail.com?subject=Regarding Brain Stroke Predictor App&body=Please send your question regarding your Brain Stroke Predictor App and we will get back to you as soon as possible"
          target="_blank"
          rel="noopener noreferrer"
        >
          contact us
        </a>
        .
      </p>

      <p>
        By using the App, you acknowledge that you have read, understood, and
        agree to be bound by this End-User License Agreement and Disclaimer. If
        you do not agree to these terms, do not use the App.
      </p>

      <p>
        This Agreement is subject to change without notice, and it is your
        responsibility to review it periodically for updates.
      </p>
    </div>
    // </div>
  );
};

export default EULA;
