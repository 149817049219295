import React, { useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import { Link } from "react-router-dom";

Modal.setAppElement("#root"); // Required for accessibility
// const URL = "http://brain.us-east-1.elasticbeanstalk.com/predict";
const URL = "https://apig.brainstrokepredictor.com/predict";

const Form = () => {
  const [formData, setFormData] = useState({
    gender: "0", // Default to female
    age: "55", // Age in years
    heart_disease: "0", // Default to No
    hypertension: "0", // Default to No
    work_type: "2", // Default to Private
    avg_glucose_level: "200", // Average Glucose Level
    weight: "150", // Weight in pounds
    height: "66", // Height in inches
    residence: "1", // Default to Urban
    smoking: "2", // Default to Never Smoked
    feet: "5",
    inches: "5",
    bmi: "",
    married: "1",
  });

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleHeightChange = (e) => {
    const { name, value } = e.target;
    let totalHeight = 0;

    const feetValue = name === "feet" ? value : formData.feet;
    const inchesValue = name === "inches" ? value : formData.inches;

    if (feetValue && inchesValue) {
      totalHeight = parseInt(feetValue, 10) * 12 + parseInt(inchesValue, 10); // Convert feet to inches and add inches
    } else if (feetValue) {
      totalHeight = parseInt(feetValue, 10) * 12; // Convert feet to inches
    } else if (inchesValue) {
      totalHeight = parseInt(inchesValue, 10); // Inches remain as inches
    }

    setFormData({
      ...formData,
      [name]: value,
      height: totalHeight,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      formData.age === "" ||
      formData.avg_glucose_level === "" ||
      formData.weight === "" ||
      formData.height === ""
    ) {
      setErrorMessage(
        "Age, Average Glucose Level, Weight, and Height are required!"
      );
      setModalIsOpen(true);
      return;
    }
    // console.log("Form data submitted:", formData);
    // Perform form submission logic here

    //bmi calclulation
    const weight = parseFloat(formData.weight);
    const height = parseFloat(formData.height);

    if (isNaN(weight) || isNaN(height) || weight <= 0 || height <= 0) {
      setErrorMessage("Please enter valid values of weight and height.");
      //console.log("Please enter valid values for weight and height");
      setModalIsOpen(true);
      return;
    }
    const bmi = (weight / (height * height)) * 703;
    formData.bmi = bmi;
    // Submit form data using Axios
    const urlEncodedData = new URLSearchParams(formData).toString();

    axios
      .post(URL, urlEncodedData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        //console.log("Form submitted successfully:", response.data);
        if (response.data.includes("No")) {
          //console.log("No stroke risk found in the response");
          setErrorMessage("Patient has no or low stroke risk.");
        } else {
          setErrorMessage("Patient has stroke risk.");
          //console.log("Stroke risk detected in the response");
        }
        // setErrorMessage("Success" + response);
        setModalIsOpen(true);
      })
      .catch((error) => {
        console.error("There was an error submitting the form:", error);
        setErrorMessage(
          "Sorry, there was an error submitting the form. Please try again later."
        );
        setModalIsOpen(true);
      });
  };

  const handleReset = () => {
    setFormData({
      gender: "1", // Reset to default male
      age: "", // Reset to empty
      heart_disease: "0", // Reset to default No
      hypertension: "0", // Reset to default No
      work_type: "2", // Reset to default Private
      avg_glucose_level: "", // Reset to empty
      weight: "", // Reset to empty
      height: "", // Reset to empty
      residence: "1", // Reset to default Urban
      smoking: "2", // Reset to Never Smoked
      feet: "",
      inches: "",
      bmi: "",
      married: "1",
    });
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          {/* <div>
            <label htmlFor="height">Height (in):</label>
            <input
              type="number"
              id="height"
              name="height"
              style={{ width: "40px" }}
              value={formData.height}
              onChange={handleChange}
            />
          </div> */}
          <div>
            <label htmlFor="height" style={{ fontWeight: "bold" }}>
              Height:
            </label>
            <br />
            <div style={{ marginBottom: "10px" }}></div>{" "}
            {/* Space between lines */}
            <div style={{ display: "flex", alignItems: "center" }}>
              <label htmlFor="feet" style={{ marginRight: "10px" }}>
                Feet:
              </label>
              <input
                type="number"
                id="feet"
                name="feet"
                style={{ width: "40px", marginRight: "10px" }}
                value={formData.feet}
                onChange={handleHeightChange}
              />

              <label htmlFor="inches" style={{ marginRight: "10px" }}>
                Inches:
              </label>
              <input
                type="number"
                id="inches"
                name="inches"
                style={{ width: "40px", marginRight: "10px" }}
                value={formData.inches}
                onChange={handleHeightChange}
              />
            </div>
          </div>
          <div>
            <label htmlFor="weight" style={{ fontWeight: "bold" }}>
              Weight (lbs):
            </label>
            <input
              type="number"
              id="weight"
              name="weight"
              style={{ width: "40px" }}
              value={formData.weight}
              onChange={handleChange}
            />
          </div>
          <label htmlFor="gender" style={{ fontWeight: "bold" }}>
            Gender:
          </label>
          <select
            id="gender"
            name="gender"
            value={formData.gender}
            onChange={handleChange}
          >
            <option value="0">Female</option>
            <option value="1">Male</option>
          </select>
        </div>
        <div>
          <label htmlFor="age" style={{ fontWeight: "bold" }}>
            Age (years):
          </label>
          <input
            type="number"
            id="age"
            name="age"
            style={{ width: "40px" }}
            value={formData.age}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="heart_disease" style={{ fontWeight: "bold" }}>
            Heart Disease:
          </label>
          <select
            id="heart_disease"
            name="heart_disease"
            value={formData.heart_disease}
            onChange={handleChange}
          >
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        </div>
        <div>
          <label htmlFor="married" style={{ fontWeight: "bold" }}>
            Ever Married:
          </label>
          <select
            id="married"
            name="married"
            value={formData.married}
            onChange={handleChange}
          >
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        </div>
        <div>
          <label htmlFor="hypertension" style={{ fontWeight: "bold" }}>
            Hypertension:
          </label>
          <select
            id="hypertension"
            name="hypertension"
            value={formData.hypertension}
            onChange={handleChange}
          >
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        </div>
        <div>
          <label htmlFor="smoking" style={{ fontWeight: "bold" }}>
            Smoking:
          </label>
          <select
            id="smoking"
            name="smoking"
            value={formData.smoking}
            onChange={handleChange}
          >
            <option value="2">Never Smoked</option>
            <option value="3">Currently Smoke</option>
            <option value="1">Former Smoker</option>
          </select>
        </div>
        <div>
          <label htmlFor="residence" style={{ fontWeight: "bold" }}>
            Residence Type:
          </label>
          <select
            id="residence"
            name="residence"
            value={formData.residence}
            onChange={handleChange}
          >
            <option value="1">Urban</option>
            <option value="0">Rural</option>
          </select>
        </div>
        <div>
          <label htmlFor="work_type" style={{ fontWeight: "bold" }}>
            Type of Work:
          </label>
          <select
            id="work_type"
            name="work_type"
            value={formData.work_type}
            onChange={handleChange}
          >
            <option value="2">Private Job</option>
            <option value="0">Govt Job</option>
            <option value="3">Self Employed</option>
            <option value="1">Never Employed</option>
          </select>
        </div>
        <div>
          <label htmlFor="avg_glucose_level" style={{ fontWeight: "bold" }}>
            Average Glucose Level:
          </label>
          <input
            type="number"
            id="avg_glucose_level"
            name="avg_glucose_level"
            style={{ width: "40px" }}
            value={formData.avg_glucose_level}
            onChange={handleChange}
          />
        </div>
        <div style={{ marginBottom: "20px" }}></div>{" "}
        <button
          type="submit"
          style={{
            float: "left",
            padding: "10px 20px",
            backgroundColor: "#b16767",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            transition: "background-color 0.3s ease",
            marginRight: "10px",
            fontWeight: "bold", // Make button text bold
          }}
        >
          PREDICT
        </button>
        <button
          type="button"
          style={{
            float: "right",
            padding: "10px 20px",
            backgroundColor: "#c1999d", // Red color for reset button
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            transition: "background-color 0.3s ease",
            fontWeight: "bold", // Make button text bold
          }}
          onClick={handleReset}
        >
          RESET
        </button>
      </form>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Result"
        className="modal"
        overlayClassName="overlay"
      >
        {!errorMessage?.includes("troke risk") && (
          <h2 className="error-text">Error</h2>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p className="error-text">{errorMessage}</p>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            type="button"
            style={{
              padding: "10px 20px",
              backgroundColor: "#c1999d", // Red color for reset button
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              transition: "background-color 0.3s ease",
              fontWeight: "bold", // Make button text bold
            }}
            onClick={closeModal}
          >
            Close
          </button>
        </div>
        <Link to="/disclaimer">Please read Disclaimer</Link>
      </Modal>
    </div>
  );
};

export default Form;
