import React from "react";

const Disclaimer = () => {
  return (
    <div className="disclaimer-container">
      <h1>Disclaimer</h1>
      <p>
        The Brain Stroke Predictor App (the "App") is designed to provide users
        with an estimation of brain stroke risk based on the data entered.
        However, the results generated by the App are for informational purposes
        only and must not be considered as a definitive diagnosis or medical
        advice.
      </p>
      <p>
        <strong>Important:</strong> The App's predictions are not a substitute
        for a professional medical diagnosis, treatment, or consultation with a
        qualified healthcare provider. Always seek the advice of your physician
        or other qualified health provider with any questions you may have
        regarding a medical condition.
      </p>
      <p>
        <strong>Limitations:</strong> While the App uses advanced algorithms to
        provide risk estimations, it does not take into account all possible
        factors that could influence brain health. The accuracy of the
        predictions can be affected by the quality and completeness of the data
        entered by the user.
      </p>
      <p>
        <strong>Legal Notice:</strong> The developers of this App disclaim any
        liability for decisions made by users based on the information provided
        by the App. The App is provided "as is" without warranty of any kind,
        express or implied, including but not limited to the warranties of
        merchantability, fitness for a particular purpose, and non-infringement.
        In no event shall the developers be liable for any claim, damages, or
        other liability, whether in an action of contract, tort, or otherwise,
        arising from, out of, or in connection with the App or the use or other
        dealings in the App.
      </p>
    </div>
  );
};

export default Disclaimer;
