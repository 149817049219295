import React from "react";
import Form from "./Form";
const FormPage = () => {
  return (
    <div className="page-container-form">
      {/* <h4>Enter Data</h4> */}
      {/* Add your form component here */}
      <Form />
    </div>
  );
};

export default FormPage;
