import React from "react";

const Footer = () => {
  return (
    <div>
      <label
        htmlFor="weight"
        style={{
          fontWeight: "bolder",
          fontStyle: "italic",
          color: "red",
          fontSize: "12px",
        }}
      >
        © {new Date().getFullYear()} Copyright by SIYONA{" "}
      </label>
    </div>
  );
};

export default Footer;
