import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navigation from "./Navigation";
import FormPage from "./FormPage";
import PrivacyPolicyPage from "./PrivacyPolicyPage";
// import BrainHealthPage from "./BrainHealthPage";
import EULA from "./EULA";
import Disclaimer from "./Disclaimer";
import "./App.css";
import Footer from "./Footer";

const App = () => {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <div className="header-content" style={{ marginBottom: 0 }}>
            <img src="brain_stroke_predictor.png" alt="Icon" className="icon" />
            <h3>Brain Stroke Predictor</h3>
          </div>
        </header>
        <Navigation />

        <main>
          <Routes>
            <Route exact path="/" element={<FormPage />} />
            <Route path="/disclaimer" element={<Disclaimer />} />

            {/* <Route path="/form" element={<FormPage />} /> */}
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            {/* <Route path="/brain-health" element={<BrainHealthPage />} /> */}
            <Route path="/eula" element={<EULA />} />
          </Routes>
        </main>
        <Footer></Footer>
      </div>
    </Router>
  );
};

const Home = () => (
  <div className="page-container">
    {/* <div> */}
    <h2>Welcome to our React App</h2>
    <p>Select a page from the navigation menu.</p>
  </div>
);

export default App;
